import React, { useEffect } from 'react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { AuthToken } from '@/lib/auth_token';
import { getCookie } from '@/lib/auth-cookies-new';
import { Roles } from '@/types/global';

const withAuthV2 = (Page: NextPage<{ auth: AuthToken; role?: string }>) => {
  const AuthenticatedComponent = () => {
    const router = useRouter();
    const token = getCookie(null, `token`);
    const auth = new AuthToken(token);

    useEffect(() => {
      const callbackPath = router?.query?.redirectUrl ?? `/`;
      const isInvoicingRole = auth?.decodedToken?.user_id?.roles?.includes(
        Roles.gbInvoicing,
      );

      if (auth.isExpired) {
        router?.replace(
          `/api/login?redirected=true&callbackUrl=${callbackPath}`,
        );
      } else if (
        callbackPath &&
        callbackPath?.includes(`commission`) &&
        !isInvoicingRole
      ) {
        router.replace(`/dashboard/summary`);
      }
    }, [router]);

    return (
      <Page
        auth={new AuthToken(auth?.token) as any}
        role={auth?.decodedToken?.user_id?.roles?.[0]}
      />
    );
  };
  AuthenticatedComponent.displayName = Page.displayName || `NA`;
  return AuthenticatedComponent;
};

export default withAuthV2;
