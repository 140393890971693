import { useEffect } from 'react';
import { AuthToken } from 'lib/auth_token';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import withAuthV2 from '@/components/decorator/withAuthV2';
import withAuth, { AuthProps } from 'components/decorator/WithAuth';
// import HomeComponent from 'components/Home';

interface HomePageProps {
  auth: AuthToken;
}
const Home: NextPage<HomePageProps> = (props) => {
  const { auth } = props;
  const role = auth?.decodedToken?.user_id?.roles[0];
  const router = useRouter();

  useEffect(() => {
    router.replace(`/dashboard/summary`);
  }, []);

  // handle case for no role
  if (!role) {
    return null;
  }

  return null;
};

export default withAuthV2(Home as any);
