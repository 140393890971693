import * as next from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

const TOKEN_NAME = `token`;
const MAX_AGE = 60 * 60 * 24 * 30; // 30 days

export type CookieReqContext =
  | Pick<next.NextPageContext, 'req'>
  | { req: next.NextApiRequest }
  | null
  | undefined;

export type CookieResContext =
  | Pick<next.NextPageContext, 'res'>
  | { res: next.NextApiResponse }
  | null
  | undefined;

export function setTokenCookie(ctx: CookieResContext, token: string) {
  console.log(`new token cookie method, settingToken`);
  setCookie(ctx, TOKEN_NAME, token, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: process.env.NODE_ENV === `production`,
    path: `/`,
    sameSite: `lax`,
  });
}

export function removeTokenCookie(ctx: CookieResContext) {
  console.log(`removing cookie, new logic`);
  destroyCookie(ctx, TOKEN_NAME, {
    maxAge: -1,
    path: `/`,
  });
}

export function getTokenCookie(ctx: CookieReqContext) {
  const cookies = parseCookies(ctx);
  return cookies.token;
}

export function getCookie(ctx: CookieReqContext, key: string): string {
  const cookies = parseCookies(ctx);
  return cookies[key];
}
